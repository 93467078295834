import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import ReactCountdownClock from 'react-countdown-clock';
import Grid from '@material-ui/core/Grid';
import AlarmIcon from '@material-ui/icons/Alarm';
import LoopIcon from '@material-ui/icons/Loop';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import NoSleep from 'nosleep.js';

var test = false;
var noSleep = new NoSleep();

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  appbar: {
    alignItems: 'center',
  },
  paper: {
    ...theme.mixins.gutters(),
    width: 350,
    justify: 'center',
    marginTop: theme.spacing.unit * 2,
    marginLeft: theme.spacing.unit * 4,
    marginRight: theme.spacing.unit * 4,
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
  buttonsDiv: {
    width:380,
    paddingLeft: 25,
    paddingRight: 25,
  },
  button: {
    margin: theme.spacing.unit,
    marginTop: theme.spacing.unit * 2,
    fontSize: 18,
  },
});

const endAudioURL = "https://firebasestorage.googleapis.com/v0/b/speech-timer-3mins.appspot.com/o/end.mp3?alt=media&token=2008c321-5700-4bf2-8b17-4ba19488f568"; 
const lastAudioURL = "https://firebasestorage.googleapis.com/v0/b/speech-timer-3mins.appspot.com/o/last.mp3?alt=media&token=19a9d7e2-a632-41dd-b7f0-a40835d08234";

const TIME_LIMIT_SEC = test?10:180;
const COLOR = '#333333';
const TITLE = "3분 타이머";
const TITLE_COLOR = "default";

const SECOND_TIME_LIMIT_SEC = test?5:45;
const SECOND_COLOR = '#AA3333';
const SECOND_TITLE = "3분 초과!!!";
const SECOND_TITLE_COLOR = "secondary";

const LAST_TITLE = "스피치 끝!"
const LAST_TITLE_COLOR = "secondary";

class App extends React.Component { 
  constructor() {
    super();
    this.state = {
      color: COLOR,
      paused: true,
      fontSize: 'auto',
      running: false,
      text: TITLE,
      textColor: TITLE_COLOR,
      status: 3,
      muted: false,
    };

    noSleep.enable();
    this.clock = null;
    this.endAudio = new Audio();
    this.lastAudio = new Audio();
    this.endAudio.preload="auto";
    this.lastAudio.preload="auto";

    this.endAudio.src = endAudioURL;
    this.lastAudio.src = lastAudioURL;
  }

  componentWillMount() {
  }

  renderStartButton = () => {
    const { classes } = this.props;
    const { running } = this.state;
    const title = (running === false)?"시작":"다시시작";
    return (
      <Button size="medium" variant="contained" color="default" className={classes.button} aria-label="Start"
        onClick={this.handleOnStart}>
        {(running === false)?<AlarmIcon/>:<LoopIcon/>}
        {title}
      </Button>
    );
  }
  renderMuteButton = () => {
    const { classes } = this.props;
    const { muted } = this.state;

    return (
      <IconButton className={classes.button} color="primary" size="large"
        onClick={this.handleOnMute}>
        {(muted === true)?<VolumeOffIcon />:<VolumeUpIcon/>}
      </IconButton>
    );
  }

  render() {
    const { classes } = this.props;
    const { color, paused, text, textColor } = this.state;
    console.log(this.state);
    return (
      <div className={classes.root}>
        <AppBar position="static" color="default" className={classes.appbar}>
          <Toolbar>
            <Typography variant="h6" color="inherit">
              Speech Timer
            </Typography>
          </Toolbar>
        </AppBar>

        <Grid container justify="center">
          <Paper className={classes.paper} elevation={1}>
            <Grid container direction="column" justify="center" alignItems="center" spacing={16}>
              <Grid item>
                <Typography variant="h5" component="h3" color={textColor}>
                {text}
                </Typography>
              </Grid>
              <Grid item>
                <ReactCountdownClock 
                  ref={(ele) => {this.clock = ele;}}
                  showMilliseconds={false}
                  timeFormat="hms"
                  alpha={1.0}
                  seconds={0}
                  pausedText="--"
                  color={color} 
                  paused={paused}
                  onComplete = {this.handleOnComplete}
                  onClick = {this.handleOnClick}/>
              </Grid>
            </Grid>
          </Paper>
          <Grid className={classes.buttonsDiv} container alignItems="center"  
              justify = "space-between">
            <Grid item> {this.renderStartButton()} </Grid>
            <Grid item> {this.renderMuteButton()} </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }

  handleOnClick = () => {
    this.setState({
      ...this.state,
      paused: !this.state.paused,
    });
  }

  handleOnComplete = () => {
    const { status } = this.state;
    if(status === 1) {
      this.startTimer(SECOND_TIME_LIMIT_SEC, SECOND_TITLE, SECOND_COLOR, SECOND_TITLE_COLOR, 2);
      this.endAudio.play();
    }
    if(status === 2) {
      this.setState({
        ...this.state,
        text: LAST_TITLE,
        textColor: LAST_TITLE_COLOR,
        paused: false,
        running: false,
      });
      noSleep.disable();
      this.lastAudio.play();
    }
  }

  handleOnStart = () => {
    noSleep.disable();
    noSleep.enable();
    this.startTimer(TIME_LIMIT_SEC, TITLE, COLOR, TITLE_COLOR, 1);
    this.endAudio.pause();
    this.endAudio.currentTime = 0;
    this.lastAudio.pause();
    this.lastAudio.currentTime = 0;
  }

  handleOnMute = () => {
    const { muted } = this.state;
    const notMuted = !muted;

    this.setState({
      ...this.state,
      muted: notMuted,
    });
    this.endAudio.muted = notMuted;
    this.lastAudio.muted = notMuted;
  }

  startTimer = (time, text, color, textColor, status) => {
    this.clock._stopTimer();
    this.clock._clearTimer();

    this.clock._seconds = time;
    this.clock._setupTimer();
    this.setState({
      ...this.state,
      paused: false,
      running: true,
      color: color,
      text: text,
      textColor: textColor,
      status: status,
    });

  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(App);
